html, body {
  width: 100%;
  overflow-x: hidden;
  position:relative;
}

body {
  margin: 0;
  font-family: 'MaruBuri-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

html {
  font-size: 20px;
}

@media (max-width: 1200px) {
  html {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  html {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 17px;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 14px;
  }
}


@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/NanumGothic-Regular.woff") format('woff2')
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src: url("../public/fonts/NanumGothic-Bold.woff") format('woff2')
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src: url("../public/fonts/NanumGothic-ExtraBold.woff") format('woff2')
}

@font-face {
  font-family: "MaruBuri-Regular";
  src: url("../public/fonts/MaruBuri-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "MaruBuri-Bold";
  src: url("../public/fonts/MaruBuri-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
